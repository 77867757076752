<template>
  <v-container fluid>
    <v-card flat style="height: 87vh; -webkit-overflow-scrolling:touch;" class="overflow-y-auto" color="#f1f1f1">
      <v-toolbar color="grey darken-4 d-none d-md-flex" dark extended flat>
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      </v-toolbar>

      <v-card class="mx-auto" max-width="1100" style="margin-top: -64px;" :loading="loading">
        <v-toolbar flat>
          <v-toolbar-title class="black--text">CONFIRMAR PEDIDO</v-toolbar-title>

          <v-spacer></v-spacer>

          <!--<v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>-->
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <v-row>
                <v-col
                  cols="12"
                  class="subtitle-2 pt-0 pb-1"
                >ELIGE UNA DIRECCIÓN GUARDADA O CREA UNA</v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="$store.state.address.address"
                    filled
                    label="Direcciones"
                    dense
                    :hint="`(${$store.state.address ? $store.state.address.name : 'Sin nombre'})`"
                    persistent-hint
                    readonly
                    @click="openAddressDialogOrder()"
                  ></v-text-field>
                  <v-btn class="mb-1" outlined @click="openAddressDialogOrder()">
                    <v-icon left>mdi-pencil</v-icon>Cambiar dirección
                  </v-btn>
                </v-col>
                <v-col cols="12" class="subtitle-2 pt-0 pb-1">RESUMEN</v-col>
                <v-col
                  cols="12"
                  align-self="center"
                  v-for="(promo, index) in $store.state.cart"
                  :key="index"
                  class="pa-0"
                >
                  <div class="pl-4 pr-4 pt-1 pb-1">
                    <v-icon>mdi-check</v-icon>
                    <span class="font-weight-bold">{{ getPedidoTextDatails(promo).name }}</span>
                    <span class="caption">{{ getPedidoTextDatails(promo).modifiers }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
                <v-simple-table dense class="pl-3 mb-3" v-if="$store.state.cart && $store.state.cart.length > 0">
                    <template v-slot:default>
                    <tbody>
                        <tr>
                        <td class="text-left pl-0"><strong>SUBTOTAL</strong></td>
                        <td class="text-right">{{ $store.state.cart.reduce((a, b) => { return a + (b.price + b.modifiersPrice) * b.quantity}, 0) | currency }}</td>
                        </tr>
                        
                        <tr>
                        <td class="text-left pl-0"><strong>DOMICILIO</strong></td>
                        <td class="text-right" v-if="delivery">{{ delivery.total | currency}}</td>
                        </tr>

                        <tr v-if="tip > 0">
                        <td class="text-left pl-0"><strong> {{ $store.state.zone.config ? $store.state.zone.config.tip.label : 'PROPINA' }} </strong></td>
                        <td class="text-right">{{ tip | currency}}</td>
                        </tr>

                        <tr v-if="cupon && cupon.id">
                        <td class="text-left pl-0"><strong>CUPÓN({{cupon.attributes.code}})</strong></td>
                        <td class="text-right">-{{ getDiscount() | currency}}</td>
                        </tr>
                        
                        <tr>
                        <td class="text-left pl-0 subtitle-1"><strong>TOTAL</strong></td>
                        <td class="text-right subtitle-1"><strong>{{ calculateTotal() | currency  }}</strong></td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>

                <v-radio-group v-model="paymentMethod" mandatory class="pb-2" hide-details v-if="paymentFestyApp && paymentFestyApp.methods.length > 0">
                    <template v-slot:label>
                        <span class="subtitle-2">MÉTODO DE PAGO</span>
                    </template>
                    <v-radio
                        v-for="(item, index) in paymentFestyApp.methods" :key="index"
                        class="pt-1"
                        :label="item.name"
                        :value="item.id"
                    >
                      <template v-slot:label>
                              <span>{{ item.name }}<strong class="caption font-weight-bold" v-if="item.id === 'transfer'">: {{item.accounts.map(u => u.name).join(' | ')}}</strong></span>
                      </template>
                    </v-radio>
                </v-radio-group>
                <v-radio-group v-model="paymentMethod" mandatory class="pb-2" hide-details v-else>
                    <template v-slot:label>
                        <span class="subtitle-2">MÉTODO DE PAGO</span>
                    </template>
                    <v-radio
                        class="pt-1"
                        :label="`Efectivo`"
                        value="cash"
                    ></v-radio>
                    <v-radio
                       v-if="storePaymentsMethodsFestyApp.length > 0"
                        value="transfer"
                    >
                        <template v-slot:label>
                            <span>Transferencia: <strong class="caption font-weight-bold">{{storePaymentsMethodsFestyApp.map(u => u.name).join(' | ')}}</strong></span>
                        </template>
                    </v-radio>
                     <v-radio
                        v-if="storePaymentsHasCardReader"
                        class="pt-1"
                        :label="`Datáfono(T. Credito / Debito)`"
                        value="cardReader"
                    ></v-radio>
                </v-radio-group>
                <v-alert
                  v-if="paymentMethod === 'transfer'"
                  dense
                  type="info"
                  class="pl-2 pr-2 mb-2 body-2"
                >
                  Una vez realices tu pedido prodrás ver las cuentas y adjuntar el comprobante para que el negocio reciba tu pedido
                </v-alert>

                <v-alert
                  v-if="paymentMethod === 'paymentGateway'"
                  dense
                  type="info"
                  class="pl-2 pr-2 mb-2 body-2"
                >
                  A continuación podrás seleccionar tus tarjetas guardadaos o agregar una nueva. Para seguir presiona el botón "Realizar pedido"
                </v-alert>

                <v-col cols="12" class="pa-0 ma-0" v-if="$store.state.zone.config && $store.state.zone.config.tip && $store.state.zone.config.tip.enable">
                  <span class="subtitle-2 text-uppercase">{{ $store.state.zone.config.tip.label }} {{ tip > 0 ? `(${$options.filters.currency(tip)})` : '' }}</span>
                  <v-chip-group
                    mandatory
                    active-class="primary--text"
                  >
                    <v-chip small @click="setTip(0)" v-if="!$store.state.zone.config.tip.mandatory">
                      0
                    </v-chip>
                    <v-chip small @click="setTip($store.state.zone.config.tip.min)">
                      {{ $store.state.zone.config.tip.min }}
                    </v-chip>
                    <v-chip small @click="setTip($store.state.zone.config.tip.min * 3)">
                      {{ $store.state.zone.config.tip.min * 3 }}
                    </v-chip>
                    <v-chip small @click="setTip($store.state.zone.config.tip.min * 5)">
                      {{ $store.state.zone.config.tip.min * 5 }}
                    </v-chip>
                    <v-chip small @click="dialogTip = true">
                      Otro
                    </v-chip>
                  </v-chip-group>
                </v-col>
                

                <v-col cols="12" class="subtitle-2 pt-0 pb-0 pl-0 pr-0">¿TIENES UN CUPÓN DE DESCUENTO?</v-col>
                <v-btn
                    class="mt-1 mb-2"
                    @click="modalPromoCode= true"
                    small
                    :disabled="!this.$store.state.cart || !this.$store.state.cart.length > 0"
                    color="grey darken-4 white--text"
                    >
                    <v-icon left>mdi-tag</v-icon>
                    Agregar cupón
                </v-btn>
                <v-textarea
                    v-model="notes"
                    outlined
                    name="input-7-4"
                    hide-details
                    label="Comentarios"
                    value=""
                    auto-grow
                    rows="1"
                ></v-textarea>
                <v-btn
                    class="mt-2"
                    color="success"
                    block
                    :disabled="!this.$store.state.cart || !this.$store.state.cart.length > 0"
                    @click="modalConfirmOrder = true"
                    >
                    <v-icon left>mdi-check-circle</v-icon>
                    Realizar pedido
                </v-btn>
                <div class="mb-5"></div>
                <v-btn
                    class="mb-2"
                    block
                    outlined
                    @click="checkCartAgain()"
                    >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Revisar el carrito
                </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <form action="https://festyapp.back4app.io/redirect/checkout" method="post" style="display: none;">
        <input type="hidden" name="order" id="order">
        <input type="hidden" name="amount" id="amount">
        <input type="hidden" name="currency" id="currency">
        <input type="hidden" name="user" id="user">
        <input type="submit" ref="sendToStripe">
      </form>

    </v-card>

    <v-dialog v-model="dialogSaveAddress" max-width="400" scrollable :no-click-animation="true">
      <v-card class="mx-auto">
        <v-card-title class="headline d-inline-block text-truncate">
          <v-btn class="float-right" icon @click="dialogSaveAddress = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>Nueva Dirección
        </v-card-title>

        <v-card-text>
          <v-row class="pa-2">
            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newDirection.name"
                label="Nombre(Opcional)"
                outlined
                hint="Ejemplo: Mi casa, Mi oficina, Empresa"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newDirection.address"
                label="Dirección"
                outlined
                hint="Ejemplo: Calle 22 # 18 - 07"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newDirection.barrio"
                label="Barrio"
                outlined
                hint="Importante para llegar más rapido"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success darken-1">
            <v-icon>mdi-content-save</v-icon>
            <span class="ml-2" @click="saveAddress()">
              <strong>Guardar dirección</strong>
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalConfirmOrder"
      max-width="320"
      persistent
    >
      <v-card>
        <v-card-title class="headline">Confirmar pedido</v-card-title>

        <v-card-text class="body-2">
          Solo nos falta tu confirmación, ¿Tienes la dirección y los demás datos correctos?, si lo crees necesario escribe el barrio e indicaciones para el domiciliario en la caja de comentarios
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            @click="modalConfirmOrder = false"
          >
            NO, REVISAR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-5"
            text
            @click="makeOrder()"
            :loading="loadPaymentButton"
          >
            SI, REALIZAR PEDIDO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalPromoCode"
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline">Aplicar cupón de descuento</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="promoCode"
            label="Cupón de decuento"
            outlined
            hint="Ejemplo: XYZ3K"
            persistent-hint
            @input="promoUpperCase()"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn
            outlined
            @click="modalPromoCode = false"
          >
            <v-icon>mdi-close</v-icon>
            CERRAR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-5"
            dark
            @click="validatePromoCode()"
          >
            <v-icon left>mdi-send-outline</v-icon>
            APLICAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogTip"
      max-width="290"
      v-if="$store.state.zone.config && $store.state.zone.config.tip"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $store.state.zone.config.tip.label }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model.number="tipValue"
            type="number"
            :min="$store.state.zone.config.tip.min"
            :step="$store.state.zone.config.tip.min"
            max="1000000"
            label="Monto de la propina"
            outlined
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="dialogTip = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="setTip(tipValue)"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import Parse from "parse";
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: true,
      userAddress: "",
      address: [],
      dialogSaveAddress: false,
      newDirection: {
        name: "",
        address: "",
        barrio: "",
      },
      storePaymentsMethodsFestyApp: [],
      paymentMethod: '',
      storePaymentsHasCardReader: '',
      notes: '',
      modalConfirmOrder: false,
      modalPromoCode: false,
      promoCode: '',
      cupon: '',

      tip: 0,
      tipValue: 0,
      dialogTip: false,

      delivery: {
        total: 0,
        distance: 0
      },

      paymentFestyApp: '',
      loadPaymentButton: false,
      checkStatusTransaction: ''
    };
  },

  mounted() {
    this.initialize();
  },

  computed: {
    ...mapState({
      storeAddress: 'address'
    })
  },

  watch: {
    //eslint-disable-next-line
    async storeAddress(newVal, oldVal){
      await this.getNewDeliveryCost()
    }
  },

  methods: {
    async initialize() {
      this.getAddresses();
      if (window.localStorage) {
          //eslint-disable-next-line
          //console.log(JSON.parse(window.localStorage.getItem("storePaymentsMethodsFestyApp")));
          this.storePaymentsMethodsFestyApp = JSON.parse(window.localStorage.getItem("storePaymentsMethodsFestyApp"))
          this.storePaymentsHasCardReader = window.localStorage.getItem("storePaymentsHasCardReader")
          this.paymentFestyApp = window.localStorage.getItem("paymentFestyApp") != 'undefined' ? JSON.parse(window.localStorage.getItem("paymentFestyApp")) : ''

          let zone = JSON.parse(window.localStorage.getItem("festyZoneLocation"))
          if (zone.config && zone.config.tip) {
            this.tipValue = zone.config.tip.min
            if (zone.config.tip.mandatory) {
              this.tip = zone.config.tip.min
            }
          }
      }
      this.loading = false;
    },

    async getAddresses() {
      try {
        let addresses = await Parse.Cloud.run("getAddresses");
        let addresses_temp = []
        addresses.forEach(element => {
          addresses_temp.push({
            id: element.id,
            name: element.attributes.name,
            longitude: element.attributes.location.longitude, 
            latitude: element.attributes.location.latitude,
            address: element.attributes.address,
            city: element.attributes.city
          })
        });
        this.address = addresses_temp;
        await this.getNewDeliveryCost()
        //this.$store.state.address = address.length > 0 ? address[0] : "";
        //eslint-disable-next-line
        //console.log(address);
      } catch (error) {
        this.$snotify.error(`${error}`, "Direcciones", {
          timeout: 4000,
          preventDuplicates: true,
        });
      }
    },

    async saveAddress() {
      if (!this.newDirection.address || !this.newDirection.barrio) {
        this.$snotify.info(
          `Lo campos Dirección y Barrio son requeridos`,
          "Nueva Dirección",
          {
            timeout: 4000,
            preventDuplicates: true,
          }
        );
      }

      try {
        let params = {
          name: this.newDirection.name,
          address: `${this.newDirection.address}, ${this.newDirection.barrio}`,
        };

        let address = await Parse.Cloud.run("saveAddress", params);
        this.$store.state.address = address;
        if (address) {
          this.address.push(address);
        }

        this.dialogSaveAddress = false
        this.newDirection =  {
            name: "",
            address: "",
            barrio: "",
        },

        this.$snotify.success(`Dirección guardada correctamente`, "Nueva Dirección", {
          timeout: 4000,
          preventDuplicates: true,
        });

      } catch (error) {
        this.$snotify.error(`${error}`, "Direcciones", {
          timeout: 4000,
          preventDuplicates: true,
        });
      }
    },

    getPedidoTextDatails(item) {
      let pedido_array = "";
      if (item) {
        pedido_array = {
          name: `(${item.quantity}) - ${
            item.name
          } - (${this.$options.filters.currency(item.price)} c/u) ${
            item.comboOption
              ? " + (" + this.$options.filters.currency(item.comboPrice) + ")"
              : ""
          }`,
          modifiers: item.modifiersText,
        };
        return pedido_array;
      }
      return "";
    },

    checkCartAgain(){
        if (Parse.User.current() && this.$store.state.cart && this.$store.state.cart.length > 0) {
            this.$store.state.cartModelNavigation = true
            return;
        }
        this.$snotify.info(
          `El carrito de compras está vacío`,
          "Carrito",
          {
            timeout: 4000,
            preventDuplicates: true,
          }
        );
        this.$router.push('/')
    },

    async makeOrder(){
        this.loadPaymentButton = true;

        if (!this.paymentMethod || !this.$store.state.cart || !this.$store.state.cart.length > 0) {
            this.$snotify.error('No se ha podido generar la orden debido a problemas técnicos.' , 'Orden', {
                timeout: 4000,
                preventDuplicates: true
            });
            this.loadPaymentButton = false;
            return;
        }

        if (!this.$store.state.address || !this.$store.state.address.id) {
          this.$snotify.error('La dirección no es válida.' , 'Dirección', {
                timeout: 4000,
                preventDuplicates: true
            });
            this.loadPaymentButton = false;
            return;
        }

        if (this.$store.state.cart[0].minAmount && this.calculateTotalTrue() < this.$store.state.cart[0].minAmount) {
          this.$snotify.warning(`La orden debe ser mayor a ${this.$options.filters.currency(this.$store.state.cart[0].minAmount)}.` , 'Monto mínimo', {
              timeout: 4000,
              preventDuplicates: true
          });
          this.loadPaymentButton = false;
          return;
        }
        
      try {        
        let params = {
          total: this.calculateTotalTrue(),
          address: this.$store.state.address.id,
          notes: this.notes,
          discount: this.getDiscount(),
          paymentMethod: this.paymentMethod,
          promoCode: this.cupon ? this.cupon.id ? this.cupon.id : '' : '',
          store: this.$store.state.cart[0].store,
          items: JSON.stringify(this.$store.state.cart),
          deliveryCost: this.delivery.total,
          userAgent: navigator.userAgent,
          fee: this.$store.state.cart[0].fee,
          tip: this.$store.state.zone.config.tip ? this.$store.state.zone.config.tip.enable ? this.tip : undefined : undefined
        }

        let updateobject = await Parse.Cloud.run("orderV3", params);
        if (updateobject) {
          this.removeCart()

          if (this.paymentMethod === 'paymentGateway') {
            
            /*document.getElementById("order").value = updateobject.attributes.object;
            document.getElementById("amount").value = params.total;
            document.getElementById("currency").value = this.$store.state.zone.config.payment.currency;
            document.getElementById("user").value = Parse.User.current().id;
            this.$refs.sendToStripe.click();*/
            //this.$router.push(`/pay-order/${updateobject.attributes.object}`)

            let payment = this.paymentFestyApp.methods.find(item => item.id === 'paymentGateway')
            //console.log(payment);
            this.openModalGlobalPay(payment, Parse.User.current().id, Parse.User.current().attributes.email ? Parse.User.current().attributes.email : Parse.User.current().attributes.gemail, `Pedido de prueba ID: ${updateobject.attributes.object}`, params.total, updateobject.attributes.object)
            return;
          }

          this.$snotify.success(`Nueva orden creada correctamente`, "Nueva Orden", {
            timeout: 4000,
            preventDuplicates: true,
          });

          this.loadPaymentButton = false
          this.$router.push('/orders')

        }

      } catch (error) {
        this.loadPaymentButton = false
        this.$snotify.error(`${error}`, "Orden", {
          timeout: 4000,
          preventDuplicates: true,
        });
      }
        
    },

    getDeliveryCost(cart){
        return cart.reduce((c, d) => { return c < d.deliveryCost ? d.deliveryCost : c }, 0)
    },

    async getNewDeliveryCost(){
      try {
        let cart = JSON.parse(window.localStorage.getItem("cartFestyApp"))
        //console.log(cart);
        let params = {
          storeId: this.$store.state.cart.length > 0 ? this.$store.state.cart[0].store :  cart ? cart[0].store : '',
          addressId: this.$store.state.address.id,
          deliveryDefault: this.getDeliveryCost(cart)
        }
        //console.log(params);
        let delivery = await Parse.Cloud.run("calculateDeliveryPrice", params);
        //console.log(delivery);
        this.delivery = delivery
      } catch (error) {
        console.log(error);
      }
    },

    

    removeCart(){
      this.$store.commit("setCart", []);
      window.localStorage.setItem("cartFestyApp", []);
      window.localStorage.setItem("storePaymentsMethodsFestyApp", JSON.stringify([]));
      window.localStorage.setItem("storePaymentsHasCardReader", '');
    },

    async validatePromoCode(){

        if (!this.promoCode) {
            this.$snotify.error('El campo de cupón de descuento es requerido' , 'Cupón de descuento', {
                timeout: 4000,
                preventDuplicates: true
            });
            return;
        }

      try {
        let params = {
         code: this.promoCode,
          store: this.$store.state.cart[0].store,
          orderAmount: (this.$store.state.cart.reduce((a, b) => { return a + (b.price + b.modifiersPrice) * b.quantity}, 0)) //+ this.getDeliveryCost(this.$store.state.cart)
        }

        let cupon = await Parse.Cloud.run("validatePromoCode", params);

        if (!cupon) {
          this.$snotify.warning(`El cupón ha expirado, no cumple con el monto mínimo o no se puede aplicar a este producto`, "Cupón invalido", {
            timeout: 6000,
            preventDuplicates: true,
          });
          return;
        }

        this.$snotify.success(`Cupón de descuento aplicado correctamente`, "Muy bien", {
          timeout: 4000,
          preventDuplicates: true,
        });
        
        this.cupon = cupon
        this.promoCode = ''
        this.modalPromoCode = false

      } catch (error) {
        this.$snotify.error(`${error}`, "Cupón de descuento", {
          timeout: 4000,
          preventDuplicates: true,
        });
      }
        
    },

    promoUpperCase(){
      if (this.promoCode) {
        this.promoCode = this.promoCode.toUpperCase()
      }
    },

    calculateTotal(){
      if (this.cupon && this.cupon.id) {
        return (((this.$store.state.cart.reduce((a, b) => { return a + (b.price + b.modifiersPrice) * b.quantity}, 0)) + this.delivery.total) -  this.getDiscount()) + this.tip
      }
      return ((this.$store.state.cart.reduce((a, b) => { return a + (b.price + b.modifiersPrice) * b.quantity}, 0)) + this.delivery.total) + this.tip
    },

    calculateTotalTrue(){
      return ((this.$store.state.cart.reduce((a, b) => { return a + (b.price + b.modifiersPrice) * b.quantity}, 0)) + this.delivery.total) + this.tip
    },

    calculateTotalWithoutDelivery(){
      return (this.$store.state.cart.reduce((a, b) => { return a + (b.price + b.modifiersPrice) * b.quantity}, 0)) + this.tip
    },

    openAddressDialogOrder(){
      this.$emit('opendialogaddressnew')
    },

    getDiscount(){
      if (this.cupon && this.cupon.id) {
        let value_or_procent = this.cupon.attributes.value
        switch (this.cupon.attributes.type) {
          case 'percent': return (this.calculateTotalWithoutDelivery()*(Number(value_or_procent)/100))
          case 'value': return value_or_procent;
          default: return 0;
        }
      }
      return 0
    },

    setTip(value){
      if (this.$store.state.zone.config.tip.mandatory) {
         if (this.$store.state.zone.config.tip.min > Number(value)) {
          this.$snotify.error(`El monto mínimo es de ${this.$options.filters.currency(this.$store.state.zone.config.tip.min)}`, "Monto", {
            timeout: 4000,
            preventDuplicates: true,
          });
          return;
        }
      }else{
        if (typeof value !== 'number') {
          this.$snotify.error(`El monto no es válido`, "Monto", {
            timeout: 4000,
            preventDuplicates: true,
          });
          return;
        }
      }
      if (value < 0) {
        this.$snotify.error(`El monto debe ser mayor que cero`, "Monto", {
          timeout: 4000,
          preventDuplicates: true,
        });
        return;
      }
      this.tip = Number(value)
      this.dialogTip = false
    },

    openModalGlobalPay(payment, userId, email, orderDescription, amount, reference){
        let that = this
        //eslint-disable-next-line
        let paymentCheckout = new PaymentCheckout.modal({
          client_app_code: payment.config.credentials.client_app_code, // Application Code de las credenciales CLIENT
          client_app_key: payment.config.credentials.client_app_key, // Application Key de las credenciales CLIENT
          locale: 'es', // Idioma preferido del usuario (es, en, pt). El inglés se usará por defecto
          env_mode: 'stg', // `prod`, `stg`, `local` para cambiar de ambiente. Por defecto es `stg`
          onOpen: function() {
              //console.log('Modal abierto');
          },
          onClose: async function() {
              //console.log('Modal cerrado');
              that.loadPaymentButton = false;
              if (!that.checkStatusTransaction) {
                try {
                
                let params = {
                    id: reference,
                    notes: `Orden cancelada desde paymentGateway paymentID: ${payment} orderID: ${reference}`,
                    silent_mode: false
                  }
                  
                  await Parse.Cloud.run("cancelOrderByPaymentGateway", params)

                  that.$router.push(`/orders`)
                
                } catch (error) {
                  that.$snotify.error(`${error.message}` , 'Notificación', {
                    timeout: 4000,
                    preventDuplicates: true
                  });
                }
              }
          },
          onResponse: function(response) { // Funcionalidad a invocar cuando se completa el proceso de pago

              /*
                En caso de error, esta será la respuesta.
                response = {
                  "error": {
                    "type": "Server Error",
                    "help": "Try Again Later",
                    "description": "Sorry, there was a problem loading Checkout."
                  }
                }

                Cual el usuario completa el flujo en el Checkout, esta será la respuesta
                response = {  
                  "transaction":{  
                      "status":"success", // Estado de la transacción
                      "id":"CB-81011", // Id de la transacción de lado de la pasarela
                      "status_detail":3 // Para más detalles de los detalles de estado: https://developers.globalpay.com.co/api/#detalle-de-los-estados
                  }
                }
              */
            that.loadPaymentButton = false;
            //console.log('Respuesta de modal');
            //console.log(response);
            that.checkStatusTransaction = response.transaction.current_status
            if (response.transaction.current_status != 'APPROVED') {
              that.$snotify.error(`El pago no fue aprobado`, "Payment", {
                timeout: 4000,
                preventDuplicates: true,
              });
            }
            that.$router.push(`/orders`)
          }
      });

      paymentCheckout.open({
        user_id: userId,
        user_email: email, // Opcional        
        //user_phone: '7777777777', // Opcional
        order_description: orderDescription,
        order_amount: amount,
        order_vat: 0,
        order_reference: reference,
        //order_installments_type: 2, // Opcional: Para Ecuador los valores válidos son: https://developers.globalpay.com.co/api/#metodos-de-pago-tarjetas-cobro-con-token-tipos-de-diferidos. Para el resto de los países, 0 para permitir cuotas, -1 en caso contrario.
        //order_taxable_amount: 0, // Opcional: Solo disponible para Datafast (Ecuador). El importe imponible, si es cero, se calcula sobre el total. Formato: decimal con dos dígitos de fracción.
        //order_tax_percentage: 10 // Opcional: Solo disponible para Datafast (Ecuador). El porcentaje de impuestos que se aplicará a este pedido.
        //conf_exclusive_types: 'ak,ex', // Opcional: Tipos de tarjeta permitidos para esta operación. Opciones: https://developers.globalpay.com.co/api/#metodos-de-pago-tarjetas-marcas-de-tarjetas
        //conf_invalid_card_type_message: 'Tarjeta invalida para esta operación' // Opcional: Define un mensaje personalizado para mostrar para los tipos de tarjeta no válidos.
      });
      
      window.addEventListener('popstate', function() {
        paymentCheckout.close();
      });

    }

  },
};
</script>